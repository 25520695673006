export default {
  menu: {
    missao: "Quest",
    limpar: "Get Coordinates",
    gpx: "GPX",
    locais: "Location",
    eventos: "Events",
    calendario: "Calendar",
    videos: "Tutorial",
    shiny: "Check",
    raids: "Raids",
    radar: "Pokémon IV+",
    rocket: "Rocket - kecleon",
    parceiros: "Partners",
    update_interval: "Update interval",
    minutes: "minutes",
    time_remaining: "Time remaining",
    seconds: "seconds"
  },
  missao: {
    limpar: "Clean",
    downgpx: "DownGPX",
    mapa: "Map",
    copiar: "Copy",
    gerarLista: "Generate list",
    expira: "Expires on:",
    nota2: "Guys, when you get a shiny, mention me on instagram",
    // eslint-disable-next-line prettier/prettier
    nota: "Note: You need a stored RA Mapping task. Don't delete it!",
    //nota: 'Note: Remove the RA Mapping mission, no longer needed.',
    pesquisar: "Search ...",
    alerta:
      "This mission has expired! or Event did not start, or Event Ended, check time most events start/end local time, come back later!",
    evento: "Event",
    novo: "New",
    expirado: "Expired!",
    total: "Total",
    velocityAtivado: "Velocity Enabled",
    velocityDesativado: "Velocity Disabled",
    Abrir: "Open",
    breve: "Coming soon",
    flutuar: "To float"
  },
  limpar: {
    converter: "Convert",
    limpar: "Clean",
    downgpx: "DownGPX",
    mapa: "Map",
    copiar: "Copy",
    nota:
      // eslint-disable-next-line prettier/prettier
      "Note: Paste here a list of coordinates or a coordinate with text, which I'll organize for you."
  },
  locais: {
    titulo: "Places to play",
    pesquisar: "Country or City...",
    hora: "Date",
    inicio: "Starts at:",
    fim: "Ends in:",
    copiar: "Copy",
    eventos: {
      title: "Pokemon GO Events",
      subtitle: "Local time",
      info: "*Local time is derived from the time of your device."
    },
    type: {
      comunitario: "Community Day",
      raid: "Raids and Megarides",
      horaRaid: "Legendary Hour",
      horaDestaque: "Hour of Spotlight",
      liga: "Go Battle League",
      geral: "General"
    }
  },
  eventos: {
    inicio: "Start",
    fim: "End",
    termina: "Ends in",
    inicia: "Starts in",
    finalizado: "Finished!",
    semEventos: "There are no events available at the moment!",
    infografia: "Infographics",
    nota: "Note: We modified it to make it easier, now select what you want =)"
  },
  videos: {
    mensagem:
      "Special thanks to Yotubers below. =) Want to see your video here, contact us via instagram"
  },
  shiny: {
    tenho: "Trade",
    registrado: "Registered",
    troca: "Look",
    total: "All",
    familia: "Family",
    brilhante: "Shiny",
    ordem: "Order by: ",
    pokedex: "Pokedex",
    tamanho: "Size",
    nota:
      "Note: Beta version, soon we will release the final version =), for now use the print from your phone.",
    eventos: "Events"
  },
  pvp: {
    nota:
      "Note: Data for the rankings at gobattlelog.com. Source: https://pvpoke.com/"
  },
  ninhos: {
    title: "Nest coordinates",
    nota:
      "The provided coordinates are for Pokémon nests. Explore and capture various Pokémon. Currently, we do not specify which Pokémon are in each nest."
  },
  rocket: {
    erro: "Coordinates not available",
    copiar: "Copy all coordinates"
  },
  doar: {
    title: "Donate"
  },
  raid: {
    tier: "Tier",
    cp: "CP",
    form: "Form",
    gender: "Gender",
    moveset: "Moveset",
    team: "team",
    semraids: "Select Raid or you don't have any Raid at the moment!"
  },
  radar: {
    nenhum: "No Pokemon available!",
    nota:
      "Select a city to generate a new survey, wait 4 seconds for the next survey!",
    todos: "All"
  },
  cem: {
    coordenadas: "Coordinates",
    nota: "The newest coordinates will appear below.",
    copiar: "Copy"
  },
  nota: {
    notaShiny: "Chance of shiny."
  }
}
