<template>
    <div>
        <div></div>
        <router-view :remote="remote" />

        <back-to-top class="round" bottom="50px" right="0" visibleoffset="200" v-if="$route.name !== 'questSimples'">
            <button type="button" class="btn btn-info btn-to-top">
                <i class="fa fa-chevron-up"></i>
            </button>
        </back-to-top>
    </div>
</template>

<script>
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { StatusBar } from '@capacitor/status-bar'
import { AdMob, InterstitialAdPluginEvents } from '@capacitor-community/admob'
import '@fortawesome/fontawesome-free/css/all.css'
import BackToTop from 'vue-backtotop'
import { getRemoteConfig, getString, getBoolean,  ensureInitialized, activate, fetchAndActivate } from 'firebase/remote-config'
import { Dialog } from '@capacitor/dialog'
import { AppLauncher } from '@capacitor/app-launcher'
import { version } from '../package.json'
import OneSignal from 'onesignal-cordova-plugin'
import { localStorageExpires, setLocalStorage, getLocalStorage } from './components/localStorageExpires'
import { linkParser } from './utils/linkParser'
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update'

export default {
    name: 'App',
    components: {
        BackToTop
    },
    data() {
        return {
            value: {
                count: 5,
                date: null
            },
            remote: {
                mensagemPT: `<i class="fa fa-info-circle"></i> Galera, quando pegar um shiny marca no instagram  <strong><a href="https://instagram.com/coordsgo" target="_black">@coordsgo</a></strong>`,
                mensagemES: `<i class="fa fa-info-circle"></i> Bro, cuando obtengan uno shiny marca en Instagram  <strong><a href="https://instagram.com/coordsgo" target="_black">@coordsgo</a></strong>`,
                mensagemEN: `<i class="fa fa-info-circle"></i> Guys, when you get a shiny, mention me on instagram  <strong><a href="https://instagram.com/coordsgo" target="_black">@coordsgo</a></strong>`,
                msg_cor: '#673bb7',
                msg_corES: '#673bb7',
                msg_corEN: '#673bb7',
                coordenadas_ativo: false,
                coordenadas: {
                    event_br: ''
                }
            },
            versionRemote: null,
            ads: false,
            login: null
        }
    },
    watch: {
        versionRemote() {
            if (this.checkVersion(this.versionRemote) > this.checkVersion(version)) {
                this.showConfirm()
            }
        },
        ads() {
            if (this.ads == 'true') {
                this.loadInterstitial()
            }
        }
    },
    methods: {
        async getLaunchUrl() {
            const urlOpen = await App.getLaunchUrl()
            if (!urlOpen || !urlOpen.url) {
                return
            }
            this.handleDeepLink(urlOpen.url)
        },

        handleDeepLink(url) {
            console.log(url)
        },
        async loadInterstitial() {


            new Promise((resolve) => {
                const options = {
                    adId: 'ca-app-pub-9214659967456120/9408213121',
                    //teste
                    //adId: 'ca-app-pub-3940256099942544/1033173712',
                }
                AdMob.addListener(InterstitialAdPluginEvents.Loaded, info => {
                    // Subscribe prepared interstitial
                    console.log(info)
                })

                resolve(
                    AdMob.prepareInterstitial(options)
                )
            }).then(async () => {

                await AdMob.showInterstitial()

            })


        },
        async showInterstitial() {
            await AdMob.showInterstitial().then(
                value => {
                    console.log(value) // true
                },
                error => {
                    console.error(error) // show error
                }
            )
        },
        async setVersion(remoteConfig) {
            await fetchAndActivate(remoteConfig)
                .then(() => {
                    this.versionRemote = getString(remoteConfig, 'version')
                    const mensagem = getString(remoteConfig, 'mensagem')
                    const mensagemES = getString(remoteConfig, 'mensagem_es')
                    const mensagemEN = getString(remoteConfig, 'mensagem_en')
                    const msg_cor = getString(remoteConfig, 'msg_cor')
                    const msg_corES = getString(remoteConfig, 'msg_cor_es')
                    const msg_corEN = getString(remoteConfig, 'msg_cor_en')
                    this.ads = getString(remoteConfig, 'ads')
                    const coordenadas_ativo = getBoolean(remoteConfig, 'coordenadas_ativo')
                    const coordenadas = getString(remoteConfig, 'coordenadas')
                    this.remote.mensagemPT = mensagem
                    this.remote.mensagemEN = mensagemEN
                    this.remote.mensagemES = mensagemES
                    this.remote.msg_cor = msg_cor
                    this.remote.msg_corES = msg_corES
                    this.remote.msg_corEN = msg_corEN
                    this.remote.coordenadas_ativo = coordenadas_ativo
                    this.remote.coordenadas = JSON.parse(coordenadas)

                })
                .catch(err => {
                    console.error('Firebase Remote Config failed to initialize', err)
                })

        },
        async showConfirm() {
            await Dialog.alert({
                title: 'Confirme',
                message: `Atualizar`
            })
            return this.checkCanOpenUrl()
        },
        async checkCanOpenUrl() {
            const value = await AppLauncher.openUrl({ url: 'https://play.google.com/store/apps/details?id=br.com.heber.coords' })
            console.log('Can open url: ', value)
            if (this.checkVersion(this.versionRemote) > this.checkVersion(version)) {
                this.showConfirm()
            }
        },
        checkVersion(str) {
            return +str.split('.').map(i => i.padStart(3, '0')).join('')
        },
        async startFlexibleUpdate () {
            const result = await AppUpdate.getAppUpdateInfo()
            if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
                return
            }
            if (result.flexibleUpdateAllowed) {
                await AppUpdate.startFlexibleUpdate()
            }
        }
    },
    async mounted() {
        if (Capacitor.isNativePlatform()) {
        this.startFlexibleUpdate()
        }
        if(Capacitor.isPluginAvailable('App')) {
            App.addListener('appUrlOpen', (event) => {
                // Example url: https://beerswift.app/tabs/tabs2
                // slug = /tabs/tabs2

                const result = linkParser(event.url)
                if (result) this.$router.push(result.pathname + result.search)

                const slug = event.url.split('com').pop()
                console.log('slug', slug)
                // We only push to the route if there is a slug present
                if (slug) {
                    this.$router.push({
                        path: slug,
                    })
                }
            })
        }
        if(!getLocalStorage('login')) {
        localStorageExpires()
        setLocalStorage('login', true, 10)
        } else {
            //console.log('token valido')
        }
        //document.addEventListener("deviceready", OneSignalInit, false)
        if (Capacitor.isNativePlatform()) {
            OneSignal.initialize("d86452ab-9e22-45c3-a315-c5eb2b220e90")
            OneSignal.Notifications.requestPermission()
        }

        if (localStorage.getItem('_l')) {
            this.login = JSON.parse(localStorage.getItem('_l'))
        }
        if (Capacitor.isNativePlatform()) {
            AdMob.initialize({
                requestTrackingAuthorization: true,
                testingDevices: [''],
                initializeForTesting: true
            })
        }
        if (!this.login || this.login.Interstitial === '1') {
            console.log('this.loadInterstitial()')
            if (Capacitor.isNativePlatform()) {
                this.loadInterstitial()
            }
        }
        if (Capacitor.isNativePlatform()) {
            const setStatusBarStyleLight = async () => {
                await StatusBar.setBackgroundColor({ color: '#673bb7' }).catch(() => { })
            }
            setStatusBarStyleLight()
        }

        const remoteConfig = getRemoteConfig(this.$fire)
        await activate(remoteConfig)
        remoteConfig.settings.minimumFetchIntervalMillis = 2000
        remoteConfig.defaultConfig = {
            version: '0.0.0',
            mensagem: `<i class="fa fa-info-circle"></i> Galera, quando pegar um shiny marca no instagram  <strong><a href="https://instagram.com/coordsgo" target="_black">@coordsgo</a></strong>`,
            msg_cor: '#673bb7',
            ads: false
        }
        ensureInitialized(remoteConfig)
            .then(() => {
                this.setVersion(remoteConfig)
                setInterval(() => {
                    this.setVersion(remoteConfig)
                }, 12e3)
                //}, 12e4)
            })
            .catch(err => {
                console.error('Firebase Remote Config failed to initialize', err)
            })
    }
}
</script>

<style lang="scss" scoped>
.btn-to-top {
    width: 60px;
    height: 60px;
    padding: 10px 16px;
    //border-radius: 50%;
    font-size: 22px;
    line-height: 22px;
    background-color: #673bb7;
    color: #fff;
    align-items: center;
    justify-content: center;
}

.round {
    width: 60px;
    height: 60px
}
</style>

<style lang="scss">
textarea:focus, input:focus, button:focus{
    outline: none;
}
</style>

