export default {
  menu: {
    missao: "Misión",
    limpar: "Obtener Coordenada",
    gpx: "GPX",
    locais: "Lugares",
    eventos: "Eventos",
    calendario: "Calendario",
    videos: "Tutorial",
    shiny: "Check",
    raids: "Incursion",
    radar: "Pokémon IV+",
    rocket: "Rocket - kecleon",
    parceiros: "Socios",
    update_interval: "Actualizar cada",
    minutes: "minutos",
    time_remaining: "Tiempo restante",
    seconds: "segundos"
  },
  missao: {
    limpar: "Limpio",
    downgpx: "DownGPX",
    mapa: "Mapa",
    copiar: "Copiar",
    gerarLista: "Generar lista",
    expira: "Expira el:",
    nota2: "Bro, cuando obtengan uno shiny marca en Instagram",
    nota: "Nota: Necesitas una tarea de Mapeo RA almacenado ¡No Borrarlo!",
    //nota: 'Nota: Elimina la misión RA Mapping, ya no es necesaria.',
    pesquisar: "Buscar...",
    alerta:
      "¡Esta misión ha expirado! o El evento no comenzó, o El evento terminó, verifique la hora en que la mayoría de los eventos comienzan / terminan en la hora local, ¡regrese más tarde!",
    evento: "Evento",
    novo: "Nuevo",
    expirado: "¡Caducó!",
    total: "Total",
    velocityAtivado: "Velocity Habilitada",
    velocityDesativado: "Velocity Deshabilitada",
    Abrir: "Abrir",
    breve: "Breve",
    flutuar: "Flotar"
  },
  limpar: {
    converter: "Convertir",
    limpar: "Limpio",
    downgpx: "DownGPX",
    mapa: "Mapa",
    copiar: "Copiar",
    nota:
      "Nota: Pegue aquí una lista de coordenadas o una coordenada con texto, que organizaré para usted."
  },
  locais: {
    titulo: "Lugares para jugar",
    pesquisar: "País o ciudad...",
    hora: "Fecha",
    inicio: "Empieza en:",
    fim: "Termina en:",
    copiar: "Copiar",
    eventos: {
      title: "Eventos Pokemon GO",
      subtitle: "Hora Local",
      info: "*La hora local se deriva de la hora de su dispositivo."
    },
    type: {
      comunitario: "Día de la Comunidad",
      raid: "Incursiones",
      horaRaid: "Hora Legendaria",
      horaDestaque: "Hora Destacada",
      liga: "Liga de Combates",
      geral: "General"
    }
  },
  eventos: {
    inicio: "Inicio:",
    fim: "Fin",
    termina: "Termina en",
    inicia: "Empieza en",
    finalizado: "¡evento terminó!",
    semEventos: "¡No hay eventos disponibles en este momento!",
    infografia: "Infografía",
    nota:
      "Nota: Lo modificamos para hacerlo más fácil, ahora selecciona lo que quieras =)"
  },
  videos: {
    mensagem:
      "Un agradecimiento especial a los Yotubers a continuación. =) Quieres ver tu video aquí, contáctanos vía instagram"
  },
  shiny: {
    tenho: "Reemplazo",
    registrado: "Registrado",
    troca: "Mirar",
    total: "Todos",
    familia: "Familia",
    brilhante: "Variocolor",
    ordem: "Ordenar por: ",
    pokedex: "Pokedex",
    tamanho: "Tamaño",
    nota:
      "Nota: Versión Beta, pronto lanzaremos la versión final =), por ahora usa la impresión desde tu teléfono.",
    eventos: "Eventos"
  },
  pvp: {
    nota:
      "Nota: Datos de uso para las clasificaciones en gobattlelog.com. Fuente: https://pvpoke.com/"
  },
  ninhos: {
    title: "Coordenadas Nidos",
    nota:
      "Las coordenadas proporcionadas son de nidos de Pokémon. Explora y captura varios Pokémon. Actualmente, no especificamos qué Pokémon están en cada nido."
  },
  rocket: {
    erro: "Coordenadas no disponibles",
    copiar: "Copiar todas las coordenadas"
  },
  doar: {
    title: "Donar"
  },
  raid: {
    tier: "Nivel",
    cp: "CP",
    form: "Molde",
    gender: "Sexo",
    moveset: "Golpes",
    team: "Equipo",
    semraids: "¡Selecciona Raid o no tienes ningún Raid en este momento!"
  },
  radar: {
    nenhum: "¡No hay Pokémon disponibles!",
    nota:
      "Seleccione una ciudad para generar una nueva encuesta, ¡espere 4 segundos para la siguiente encuesta!",
    todos: "Todos"
  },
  cem: {
    coordenadas: "Coordenadas",
    nota: "Las coordenadas más recientes aparecerán debajo.",
    copiar: "Copiar"
  },
  nota: {
    notaShiny: "Posibilidad de brillante."
  }
}
