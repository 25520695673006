export default [
    {
        path: '/',
        component: () => import('../templates/Base.vue'),
        children: [
            {
                name: 'Missao',
                path: '',
                component: () => import('../views/Missao.vue'),
                meta: {
                    lang: 'pt',
                    titulo: 'Missão'
                }
            },
            {
                path: 'missao/:id',
                name: 'missaoId',
                component: () => import('../views/Missao.vue'),
                meta: {
                    lang: 'pt',
                    titulo: 'Missão'
                }
            },
            {
                path: 'gpx',
                name: 'Gpx',
                component: () => import('../views/Gpx.vue'),
                meta: {
                    titulo: 'GPX',
                    lang: 'pt'
                }
            },
            {
                path: 'limpar',
                name: 'Limpar',
                component: () => import('../views/Limpar.vue'),
                meta: {
                    titulo: 'Limpar',
                    lang: 'pt'
                }
            },
            {
                path: 'locais',
                name: 'Locais',
                component: () => import('../views/Locais.vue'),
                meta: {
                    titulo: 'Locais',
                    lang: 'pt'
                }
            },
            {
                path: 'eventos',
                name: 'Eventos',
                component: () => import('../views/Eventos.vue'),

                meta: {
                    titulo: 'Eventos',
                    lang: 'pt'
                }
            },
            {
                path: 'eventos-calendar',
                name: 'EventosCalendar',
                component: () => import('../views/EventosCalendar.vue'),

                meta: {
                    titulo: 'Eventos Calendar',
                    lang: 'pt'
                }
            },
            {
                path: 'pvp-ranking',
                name: 'Pvp',
                component: () => import('../views/Pvp.vue'),
                meta: {
                    titulo: 'Coords Pvp Ranking',
                    lang: 'pt'
                }
            },
            {
                path: 'eventos/:id/:text',
                name: 'EventosId',
                component: () => import('../views/EventosId.vue'),
                meta: {
                    titulo: 'Eventos',
                    lang: 'pt'
                }
            },
            {
                path: 'videos',
                name: 'videos',
                component: () => import('../views/Videos.vue'),
                meta: {
                    titulo: 'Vídeos',
                    lang: 'pt'
                }
            },
            {
                path: 'login',
                name: 'login',
                component: () => import('../views/Login.vue'),
                meta: {
                    titulo: 'Login',
                    lang: 'pt'
                }
            },
            {
                path: 'coords/:id',
                name: 'Coords',
                component: () => import('../views/Coords.vue'),
                meta: {
                    titulo: 'Coords',
                    lang: 'pt'
                }
            },
            {
                path: 'pkm100',
                name: 'Coords100',
                component: () => import('../views/Pokemon100.vue'),
                meta: {
                    titulo: 'Coords',
                    lang: 'pt'
                }
            },
            {
                path: 'coordsadmin/:id',
                name: 'CoordsAdmin',
                component: () => import('../views/CoordsAdmin.vue'),
                meta: {
                    titulo: 'Coords',
                    lang: 'pt'
                }
            },
            {
                path: 'ninhos',
                name: 'Ninhos',
                component: () => import('../views/Ninhos.vue'),
                meta: {
                    titulo: 'Ninhos',
                    lang: 'pt'
                }
            },
            {
                path: 'rocket',
                name: 'Rocket',
                component: () => import('../views/Rocket.vue'),
                meta: {
                    titulo: 'Rocket',
                    lang: 'pt'
                }
            },
            {
                path: 'raids',
                name: 'Raids',
                component: () => import('../views/Raids.vue'),
                meta: {
                    titulo: 'Raids',
                    lang: 'pt'
                }
            },
            {
                path: 'pkm90',
                name: 'pkm90',
                component: () => import('../views/Radar.vue'),
                meta: {
                    titulo: 'pkm90',
                    lang: 'pt'
                }
            },
            {
                path: 'parceiros',
                name: 'parceiros',
                component: () => import('../views/Parceiros.vue'),
                meta: {
                    titulo: 'pkm',
                    lang: 'pt'
                }
            },

        ]
    },
    {
      path: '/quest',
      name: 'questSimples',
      component: () => import('../views/QuestSimples.vue'),
      meta: {
        titulo: 'pkm',
        lang: 'pt'
      }
    },
]
