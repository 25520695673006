export default {
  menu: {
    missao: "Missão",
    limpar: "Limpar Coordenada",
    gpx: "GPX",
    locais: "Locais Horários",
    eventos: "Eventos",
    calendario: "Calendário",
    videos: "Tutorial",
    shiny: "Check",
    raids: "Reides",
    radar: "Pokémon IV+",
    rocket: "Rocket - kecleon",
    parceiros: "Parceiros",
    update_interval: "Atualizar a cada",
    minutes: "minutos",
    time_remaining: "Tempo restante",
    seconds: "segundos"
  },
  missao: {
    limpar: "Limpar",
    downgpx: "Download GPX",
    mapa: "Mapa",
    copiar: "Copiar",
    gerarLista: "Copiar Rota",
    expira: "Expira em:",
    nota2: "Galera, quando pegar um shiny marca no instagram",
    nota:
      "Nota: Você precisa de uma tarefa de Mapeamento RA armazenada. Não a exclua!",
    //nota: 'Nota: Remova a missão Mapeamento RA, não necessário mais.',
    pesquisar: "Pesquisar...",
    alerta:
      "Essa missão expirou! ou o Evento não iniciou, ou o Evento Terminou, consulte o horário a maioria dos eventos iniciar/termina no horário local, volte mais tarde!",
    evento: "Evento",
    novo: "Novo",
    expirado: "Expirado!",
    total: "Total",
    velocityAtivado: "Velocity Ativado",
    velocityDesativado: "Velocity Desativado",
    Abrir: "Abrir",
    breve: "Breve",
    flutuar: "Flutuar"
  },
  limpar: {
    converter: "Converter",
    limpar: "Limpar",
    downgpx: "DownGPX",
    mapa: "Mapa",
    copiar: "Copiar",
    nota:
      "Nota: Cole aqui uma lista de coordenadas ou uma coordenada com texto, que organizarei pra você."
  },
  locais: {
    titulo: "Locais para jogar",
    pesquisar: "Pais ou Cidade...",
    hora: "Data",
    inicio: "Inicia em:",
    fim: "Finaliza em:",
    copiar: "Copiar",
    eventos: {
      title: "Eventos Pokemon GO",
      subtitle: "Hora Local",
      info: "*A hora local provém da hora do seu dispositivo."
    },
    type: {
      comunitario: "Dia Comunitário",
      raid: "Reides e Megarreides",
      horaRaid: "Hora Lendária",
      horaDestaque: "Hora de Holofote",
      liga: "Liga de Batalha GO",
      geral: "Geral"
    }
  },
  eventos: {
    inicio: "Início",
    fim: "Fim",
    termina: "Termina em",
    inicia: "Inicia em",
    finalizado: "Finalizado!",
    semEventos: "Não há eventos disponiveis no momento!",
    infografia: "Infográficos",
    nota: "Nota: Modificamos para facilitar, agora selecione o que você quer =)"
  },
  videos: {
    mensagem:
      "Agradecimento especial aos Yotubers abaixo. =) Quer ver seu vídeo aqui, entre em contato pelo instagram"
  },
  shiny: {
    tenho: "Troca",
    registrado: "Registrado",
    troca: "Procuro",
    total: "Todos",
    familia: "Família",
    brilhante: "Brilhante",
    ordem: "Ordenar por: ",
    pokedex: "Pokedex",
    tamanho: "Tamanho",
    nota:
      "Nota: Versão beta, logo iremos liberar a versão final =), no momento utilize o print do seu celular.",
    eventos: "Eventos"
  },
  pvp: {
    nota:
      "Nota: Dados de uso para as classificações em gobattlelog.com. Fonte: https://pvpoke.com/"
  },
  ninhos: {
    title: "Coordenadas de Ninhos",
    nota:
      "As coordenadas fornecidas são de ninhos de Pokémons. Explore e capture vários Pokémons. No momento, não informamos quais Pokémons estão em cada ninho."
  },
  rocket: {
    erro: "Coordenadas não disponíveis",
    copiar: "Copiar todas as coordenadas"
  },
  doar: {
    title: "Doar"
  },
  raid: {
    tier: "Nível",
    cp: "PC",
    form: "Forma",
    gender: "Sexo",
    moveset: "Golpes",
    team: "Time",
    semraids: "Selecione a Reide ou não tem nenhuma Reide no momento!"
  },
  radar: {
    nenhum: "Nenhum Pokémon disponível!",
    nota:
      "Selecione uma cidade pra gerar nova pesquisa, aguarde 4 segundos pra uma próxima pesquisa!",
    todos: "Todos"
  },
  cem: {
    coordenadas: "Coordenadas",
    nota: "As coordenadas mais novas vão aparecendo abaixo.",
    copiar: "Copiar"
  },
  nota: {
    notaShiny: "Possibilidade de shiny."
  }
}
