import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import VueCountdownTimer from 'vuejs-countdown-timer'
import vuePositionSticky from 'vue-position-sticky'
import Toasted from 'vue-toasted'
import { firestorePlugin } from 'vuefire'
import LongPress from '@jcharante/vue-long-press-directive'
import VueToast from 'vue-toast-notification'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import messages from './i18n'
import 'vue-toast-notification/dist/theme-sugar.css'
import { app } from './services/firebase'
import './assets/tailwind.css'

Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(VueI18n)
Vue.use(VueCountdownTimer)
Vue.use(vuePositionSticky)
Vue.use(Toasted)
Vue.use(firestorePlugin)
Vue.use(VueToast)
Vue.use(LongPress, { duration: 500 })

const i18n = new VueI18n({
    locale: 'pt-br',
    fallbackLocale: 'pt-br',
    messages
})

//const protocol = window.location.protocol
const url = 'https://api.pokemon.sistemaweb.com.br'
//const url = 'http://localhost:8000'
/* const url =
  window.location.hostname === 'localhost' ?
  'http://localhost:8000' :
  'https://api.pokemon.sistemaweb.com.br' */
Vue.prototype.$url = url
Vue.prototype.$fire = app

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
